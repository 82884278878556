#queueSelect:hover {
  cursor: pointer;
}

.latestLiveTransfers {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background: #ffffff;
  padding: 15px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

.wrapper {
  margin-left: 16vw;
}