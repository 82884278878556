.disabled-agent-row {
  background-color: #ffeaea;
}

.enable-agent-account-text {
  color: #007bff;

}

.enable-agent-account-text:hover {
  cursor: pointer;
  text-decoration: underline;
}